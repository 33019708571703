import React, {Component} from 'react';
import lib from 'library'
import MediaQuery from 'react-responsive'


class PhotoGallery extends Component {

  constructor (props) {
    super(props)
    this.state = {
      position: 0,
      interval: null,
      array: [
        require('../assets/image/Photos_1.jpg'),
        require('../assets/image/Photos_2.jpg'),
        require('../assets/image/Photos_3.jpg'),
      ]
    }

  }

  componentDidMount(){

    this.setState({interval: setInterval(() => {
        this.setState({position: this.state.position === this.state.array.length - 1 ? 0 : this.state.position + 1});
    }, 3000)});

  }

  componentWillUnmount() {

        clearInterval(this.state.interval);

    }


    render(){
      return(

          <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <MediaQuery minDeviceWidth={700}>
            {(matches) => {
            if (matches) {
              return <div>
                <img src={this.state.array[this.state.position]} alt='Photo' />
              </div>;
            } else {
              return <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <img style={{
                    width: '100%',
                    height: 'auto',
                    maxHeight: 1024,
                    maxWidth: 768
                  }} src={this.state.array[0]} alt='Photo' />
                  <img style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: 1024,
                      maxWidth: 768
                    }} src={this.state.array[1]} alt='Photo' />
                    <img style={{
                        width: '100%',
                        height: 'auto',
                        maxHeight: 1024,
                        maxWidth: 768
                      }} src={this.state.array[2]} alt='Photo' />
              </div>;
            }
          }}
          </MediaQuery>

          </div>

      )
  }
}

export default PhotoGallery
